<template>
  <standard v-bind:hero="hero">
    <main class="section container content">
      <h1 class="title is-size-1-tablet">Impressum</h1>
      <p>
        <strong>Studio57 GmbH</strong><br>
        Köpenicker Str. 20<br>
        10997 Berlin
      </p>
      <p>
        Telefon: 030-26933670<br>
        Mail: <a href="mailto:hello@studio57-berlin.de">hello@studio57-berlin.de</a>
      </p>
      <p>
        Geschäftsführerin: Rotraud Bolien<br>
        USt-ID-Nr: DE305089842
      </p>
      <p class="mt-6">
        Die Inhalte dieser Internetseite wurden sorgfältig geprüft und nach bestem Wissen erstellt. Für die hier dargebotenen Informationen wird jedoch kein Anspruch auf Vollständigkeit, Aktualität, Qualität und Richtigkeit erhoben.
      </p>
      <p>
        Fremde Internetseiten werden in einem neuen Browserfenster geöffnet. Trotz sorgfältiger, inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
      </p>
    </main>
  </standard>
</template>

<script>
  import Standard from '../layouts/standard.vue'

  export default {
    data: function () {
        return { }
    },
    components: {
      Standard
    }
  }
</script>
